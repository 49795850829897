body {
 margin: 0;
 width: 100%;
 height: 100%;
}

#root {
 height: 100vh;
 width: 100%;
}

.app {
 height: 100%;
 width: 100%;
 box-sizing: border-box;
 display: flex;
 flex-direction: column;
 background: #222222;
}

.app-bar {
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin-bottom: 7%;
 padding: 16px 40px;
}

.logo-container {
 display: flex;
 align-items: center;
}

.logo-container img {
 margin-right: 4px;
}

.logo-subtext {
 font-size: 18px;
 font-weight: 500;
 padding-bottom: 4px;
 padding-left: 2px;
 -webkit-font-smoothing: antialiased;
}

.dev-text-container {
 display: flex;
 align-items: center;
}

.dev-text-container img {
 margin-bottom: 10px;
 margin-left: 5px;
}

.dev-text {
 font-weight: 500;
 font-size: 16px;
 line-height: 120%;
 color: #707070;
 display: flex;
}

.title {
 text-align: center;
 font-weight: 500;
 font-size: 24px;
 line-height: 113%;
}

.title span {
 background: linear-gradient(-90deg, #FF8254, #FFBD80, #FFE870);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

.subtitle-container {
 text-align: center;
 width: 100%;
 display: flex;
 justify-content: center;
}

.subtitle {
 width: 90%;
 max-width: 600px;
 font-weight: 300;
 font-size: 16px;
 line-height: 25px;
 margin-top: 20px;
}

.domain-resolver {
 width: 100%;
 margin-top: 8%;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}

.domain-resolver-container {
 width: 100%;
 max-width: 600px;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 10%;
}

.theme-text {
 color: white;
}

.support {
 max-width: 540px;
 width: 90%;
 padding-top: 10px;
 justify-content: end;
}

.theme-btn-container {
 max-width: 540px;
 width: 90%;
 display: flex;
 justify-content: end;
 padding-bottom: 10px;
}

.theme-btn-container img {
 width: 24px;
 height: 24px;
}

.cursor-pointer {
 cursor: pointer;
}

.footer {
 flex: 1;
 display: flex;
 flex-direction: column;
 align-items: start;
 justify-content: end;
 padding: 30px;

 font-weight: 500;
 font-size: 10px;
 line-height: 120%;
 color: #707070;
}

.link:hover {
 background: linear-gradient(-90deg, #FF8254, #FFBD80, #FFE870);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

@media (max-width: 700px) {
 .app-bar {
  flex-direction: column;
  margin-bottom: 20%;
 }

 .dev-text-container {
  margin-top: 20px;
 }
 .domain-resolver {
  margin-top: 15%;
 }
}

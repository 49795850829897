body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100vh;
}

.app {
  box-sizing: border-box;
  background: #222;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.app-bar {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7%;
  padding: 16px 40px;
  display: flex;
}

.logo-container {
  align-items: center;
  display: flex;
}

.logo-container img {
  margin-right: 4px;
}

.logo-subtext {
  -webkit-font-smoothing: antialiased;
  padding-bottom: 4px;
  padding-left: 2px;
  font-size: 18px;
  font-weight: 500;
}

.dev-text-container {
  align-items: center;
  display: flex;
}

.dev-text-container img {
  margin-bottom: 10px;
  margin-left: 5px;
}

.dev-text {
  color: #707070;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 113%;
}

.title span {
  background: linear-gradient(-90deg, #ff8254, #ffbd80, #ffe870);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.subtitle-container {
  text-align: center;
  justify-content: center;
  width: 100%;
  display: flex;
}

.subtitle {
  width: 90%;
  max-width: 600px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}

.domain-resolver {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8%;
  display: flex;
}

.domain-resolver-container {
  justify-content: center;
  align-items: center;
  gap: 10%;
  width: 100%;
  max-width: 600px;
  display: flex;
}

.theme-text {
  color: #fff;
}

.support {
  justify-content: end;
  width: 90%;
  max-width: 540px;
  padding-top: 10px;
}

.theme-btn-container {
  justify-content: end;
  width: 90%;
  max-width: 540px;
  padding-bottom: 10px;
  display: flex;
}

.theme-btn-container img {
  width: 24px;
  height: 24px;
}

.cursor-pointer {
  cursor: pointer;
}

.footer {
  color: #707070;
  flex-direction: column;
  flex: 1;
  justify-content: end;
  align-items: start;
  padding: 30px;
  font-size: 10px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
}

.link:hover {
  background: linear-gradient(-90deg, #ff8254, #ffbd80, #ffe870);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media (width <= 700px) {
  .app-bar {
    flex-direction: column;
    margin-bottom: 20%;
  }

  .dev-text-container {
    margin-top: 20px;
  }

  .domain-resolver {
    margin-top: 15%;
  }
}

/*# sourceMappingURL=index.54f735ee.css.map */
